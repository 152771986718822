




























import { Component } from 'vue-property-decorator'
import InputBox from '../../common/input-box/inputBox.vue'
import API from '@/api/index.js'
import myMixins from '@/mixins/common.ts'

// 这里相当于手机号注册

@Component({
    components: {
        InputBox
    }
})
export default class SetPassword extends myMixins {
    password = {
        first: '',
        second: ''
    }
    inputTypeA = 'password'
    inputTypeB = 'password'
    canClick = false

    valueChange(params: Param) {
        const { name, val } = params
        this.password[name] = val
        const { first, second } = this.password
        if (first !== '' && first.length >= 8 && JSON.stringify(first) === JSON.stringify(second)) {
            this.canClick = true
        } else {
            this.canClick = false
        }
    }

    inputTypeChange(params: Param) {
        const { name, val } = params
        this[name] = val
    }

    async register() {
        if (this.canClick) {
            const { wxTokenID, timeSmsCode, timePhone } = this.$store.state.login
            const res = await API.register({
                phone: timePhone,
                smsCode: timeSmsCode,
                tokenID: wxTokenID,
                password: this.password.first
            })
            this.login(res)
        }
    }
}
